<template>
  <div>
    <div class="left f-hide">
      <!-- <el-scrollbar style="height: calc(100%)">
        <div class="one" @click="istrue=!istrue"> <span>资产管理</span> <i class="el-icon-arrow-down iconImg"
            v-if="!istrue"></i>
          <i v-else class="el-icon-arrow-up iconImg"></i>
        </div>
        <div class="outer-box" v-if="istrue">
          <el-link :underline="false" @click="$router.push('/manage'), linClick(1)" class="two"
            :class="active == 1 ? 'two-active' : ''">我的银行卡</el-link>
          <el-link :underline="false" @click="$router.push('/myAssets'), linClick(2)" class="two"
            :class="active == 2 ? 'two-active' : ''">我的资产</el-link>
          <el-link :underline="false" @click="$router.push('/jiaoyi'), linClick(3)"
            :class="active == 3 ? 'two-active' : ''" class="two">交易记录</el-link>
          <el-link :underline="false" @click="$router.push('/finance'), linClick(4)"
            :class="active == 4 ? 'two-active' : ''" class="two">账户安全</el-link>
        </div>
        <div class="one" @click="istrue1=!istrue1"><span>能源数据管理</span>
          <i class="el-icon-arrow-down iconImg" v-if="!istrue1"></i>
          <i v-else class="el-icon-arrow-up iconImg"></i>
        </div>
        <div class="outer-box" v-if="istrue1">
          <div style="font-size: 18px;padding-left: 32px;" @click="istrue9=!istrue9">煤矿企业
            <i class="el-icon-arrow-down iconImg" v-if="!istrue9"></i>
            <i v-else class="el-icon-arrow-up iconImg"></i>
          </div>
          <div class="outer-box" v-if="istrue9">
            <el-link :class="active == 60? 'two-active' : ''" :underline="false"
              @click="$router.push('/Mining'),linClick(60)" class="two">生产煤矿</el-link>
            <el-link :class="active == 61? 'two-active' : ''" :underline="false" @click="linClick('no')"
              class="two">在建煤矿</el-link>
          </div>
          <el-link :class="active == 33? 'two-active' : ''" style="font-size: 18px" :underline="false"
            @click="linClick('no')" class="two">电力企业</el-link>
          <el-link :class="active == 30? 'two-active' : ''" :underline="false" style="font-size: 18px"
            @click="linClick('no')" class="two">仓储企业</el-link>

        </div>
        <div class="one" @click="istrue10=!istrue10"><span>中长期管理</span>
          <i class="el-icon-arrow-down iconImg" v-if="!istrue10"></i>
          <i v-else class="el-icon-arrow-up iconImg"></i>
        </div>

        <div class="outer-box" v-if="istrue10">
          <el-link :class="active == 17 ? 'two-active' : ''" :underline="false"
            @click="$router.push('/certification'), linClick(17)" class="two">中长期合同管理</el-link>
        </div>




        <div class="one" @click="istrue3=!istrue3"><span>交易管理</span>
          <i class="el-icon-arrow-down iconImg" v-if="!istrue3"></i>
          <i v-else class="el-icon-arrow-up iconImg"></i>
        </div>
        <div class="outer-box outer-box1" v-if="istrue3">
          <div class="two">竞价销售 <i class="el-icon-arrow-down"></i></div>
          <el-link :underline="false" :class="active == 5 ? 'two-active' : ''" @click="
              $router.push({
                path: '/released',
                query: {
                  title: '竞价销售',
                  type: '我发布的',
                },
              }),
                linClick(5)
            " class="three" type="info">我发布的</el-link><el-link :underline="false"
            :class="active == 6 ? 'two-active' : ''" @click="
              $router.push({
                path: '/participation',
                query: {
                  title: '竞价销售',
                  type: '我参与的',
                },
              }),
                linClick(6)
            " class="three" type="info">我参与的</el-link>
          <div class="two">竞价采购 <i class="el-icon-arrow-down"></i></div>
          <el-link :underline="false" :class="active == 7 ? 'two-active' : ''" @click="
              $router.push({
                path: '/purchase',
                query: {
                  title: '竞价采购',
                  type: '我发布的',
                },
              }),
                linClick(7)
            " class="three" type="info">我发布的</el-link><el-link :underline="false"
            :class="active == 8 ? 'two-active' : ''" @click="
              $router.push({
                path: '/take',
                query: {
                  title: '竞价采购',
                  type: '我参与的',
                },
              }),
                linClick(8)
            " class="three" type="info">我参与的</el-link>

          <div class="two">挂牌采购 <i class="el-icon-arrow-down"></i></div>
          <el-link :underline="false" :class="active == 9 ? 'two-active' : ''" @click="
              $router.push({
                path: '/purchasehangtag',
                query: {
                  title: '挂牌采购',
                  type: '我发布的',
                },
              }),
                linClick(9)
            " class="three" type="info">我发布的</el-link>

          <div class="two">挂牌销售 <i class="el-icon-arrow-down"></i></div>
          <el-link :underline="false" :class="active == 10 ? 'two-active' : ''" @click="
              $router.push({
                path: '/markethangtag',
                query: {
                  title: '挂牌销售',
                  type: '我发布的',
                },
              }),
                linClick(10)
            " class="three" type="info">我发布的</el-link>

          <div class="two" style="width: 136px; font-weight: 550" :class="active == 11 ? 'two-active' : ''" @click="
              $router.push({
                path: '/order',
                query: {
                  title: '订单信息',
                },
              }),
                linClick(11)
            ">
            订单信息<span style="width: 20px; display: inline-block"></span>
          </div>
          <div class="two" style="width: 136px; font-weight: 550" :class="active == 16 ? 'two-active' : ''" @click="
          $router.push({
            path: '/contract',
            query: {
              title: '合同管理',
            },
          }),
            linClick(16)
        ">
            合同管理<span style="width: 20px; display: inline-block"></span>
          </div>
        </div>
        <div class="one" @click="istrue4=!istrue4"><span>人员管理</span>
          <i class="el-icon-arrow-down iconImg" v-if="!istrue4"></i>
          <i v-else class="el-icon-arrow-up iconImg"></i>
        </div>
        <div class="outer-box" v-if="istrue4">
          <el-link :underline="false" :class="active == 12 ? 'two-active' : ''"
            @click="$router.push('/personnelStructure'), linClick(12)" class="two">人员架构</el-link>
          <el-link :underline="false" :class="active == 13 ? 'two-active' : ''"
            @click="$router.push('/record'), linClick(13)" class="two">操作记录</el-link>
        </div>
        <div class="one" @click="istrue6=!istrue6"><span>签章管理</span>
          <i class="el-icon-arrow-down iconImg" v-if="!istrue6"></i>
          <i v-else class="el-icon-arrow-up iconImg"></i>
        </div>
        <div class="outer-box" v-if="istrue6">
          <el-link :class="active == 15 ? 'two-active' : ''" :underline="false"
            @click="$router.push('/qianzhang'), linClick(15)" class="two">我的签章</el-link>
        </div>


        <div class="one" @click="istrue5=!istrue5"><span>我的收藏</span>
          <i class="el-icon-arrow-down iconImg" v-if="!istrue5"></i>
          <i v-else class="el-icon-arrow-up iconImg"></i>
        </div>
        <div class="outer-box" v-if="istrue5">
          <el-link :class="active == 14 ? 'two-active' : ''" :underline="false"
            @click="$router.push('/guanzhu'), linClick(14)" class="two">收藏的商品</el-link>
        </div>
        <div class="one" @click="istrue2=!istrue2"><span>服务大厅管理</span>
          <i class="el-icon-arrow-down iconImg" v-if="!istrue2"></i>
          <i v-else class="el-icon-arrow-up iconImg"></i>
        </div>
        <div class="outer-box" v-if="istrue2">
          <el-link :underline="false" @click="$router.push('/myPublished'), linClick(18)" class="two"
            :class="active == 18 ? 'two-active' : ''">我发布的服务</el-link>
          <el-link :underline="false" @click="$router.push('/financeTax'), linClick(19)" class="two"
            :class="active == 19 ? 'two-active' : ''">财税服务订单</el-link>
          <el-link :underline="false" @click="$router.push('/banking'), linClick(20)"
            :class="active == 20 ? 'two-active' : ''" class="two">金融服务订单</el-link>
          <el-link :underline="false" @click="$router.push('/logistics'), linClick(21)"
            :class="active == 21 ? 'two-active' : ''" class="two">物流服务订单</el-link>
          <el-link :underline="false" @click="$router.push('/warehouse'), linClick(22)"
            :class="active == 22 ? 'two-active' : ''" class="two">仓储服务订单</el-link>
          <el-link :underline="false" @click="$router.push('/science'), linClick(23)"
            :class="active == 23 ? 'two-active' : ''" class="two">科技服务订单</el-link>
        </div>

        <div class="one" @click="istrue7=!istrue7"><span>我的服务</span>
          <i class="el-icon-arrow-down iconImg" v-if="!istrue7"></i>
          <i v-else class="el-icon-arrow-up iconImg"></i>
        </div>
        <div class="outer-box" v-if="istrue7">
          <el-link :underline="false" @click="$router.push('/usmyPublished'), linClick(24)" class="two"
            :class="active == 24 ? 'two-active' : ''">我关注的服务商品</el-link>
          <el-link :underline="false" @click="$router.push('/usfinanceTax'), linClick(25)" class="two"
            :class="active == 25 ? 'two-active' : ''">财税服务订单</el-link>
          <el-link :underline="false" @click="$router.push('/usbanking'), linClick(26)"
            :class="active == 26 ? 'two-active' : ''" class="two">金融服务订单</el-link>
          <el-link :underline="false" @click="$router.push('/uslogistics'), linClick(27)"
            :class="active == 27 ? 'two-active' : ''" class="two">物流服务订单</el-link>
          <el-link :underline="false" @click="$router.push('/uswarehouse'), linClick(28)"
            :class="active == 28 ? 'two-active' : ''" class="two">仓储服务订单</el-link>
          <el-link :underline="false" @click="$router.push('/usscience'), linClick(29)"
            :class="active == 29 ? 'two-active' : ''" class="two">科技服务订单</el-link>
        </div>

      </el-scrollbar> -->

      <el-tree
        :data="data"
        :props="defaultProps"
        accordion
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/action-types";
import { getSysDictDataByByDictType } from "@/api/hy";
import Vue from "vue";
export default {
  data() {
    let ifOpenFwdt = Vue.ls.get(types.IF_OPEN_FWDT);
    let formatOpenFwdt = false;
    if (ifOpenFwdt != undefined && ifOpenFwdt != "" && ifOpenFwdt) {
      // 开启服务大厅
      formatOpenFwdt = ifOpenFwdt;
    }
    return {
      // istrue: true,
      // istrue1: false,
      // istrue2: false,
      // istrue3: false,
      // istrue4: false,
      // istrue5: false,
      // istrue6: false,
      // istrue7: false,
      // istrue8: false,
      // istrue9: false,
      // istrue10: false,
      ifOpenFwdt: formatOpenFwdt, // 是否开启服务大厅
      data: [
        {
          label: "资产管理",
          children: [
            {
              label: "我的银行卡",
              path: "/manage",
              index: 1,
            },
            {
              label: "我的资产",
              path: "/myAssets",
              index: 2,
            },
            {
              label: "交易记录",
              path: "/jiaoyi",
              index: 3,
            },
            {
              label: "账户安全",
              path: "/finance",
              index: 4,
            },
          ],
        },

        // {
        //   label: '能源数据管理',
        //   children: [
        //     {
        //       label: "基础信息",
        //       path: "/BsInformation",
        //       index: 30
        //     },
        //     {
        //       label: "数据报送",
        //       path: "/Mining",
        //       index: 5,
        //     },
        //     // {
        //     //   label: "生产煤矿",
        //     //   children: [
        //     //     {
        //     //       label: "基础信息",
        //     //       path: "/BsInformation",
        //     //       index: 30
        //     //     },
        //     //     {
        //     //       label: "数据报送",
        //     //       path: "/Mining",
        //     //       index: 5,
        //     //     },
        //     //   ]
        //     // },
        //     // {
        //     //   label: "电力企业",
        //     //   path: "no",
        //     // }, {
        //     //   label: "仓储企业",
        //     //   path: "no",
        //     // },
        //   ],
        // },

        // {
        //   label: '中长期管理',
        //   children: [
        //     {
        //       label: "中长期合同管理",
        //       path: "/certification",
        //       index: 44,
        //     },
        //   ],
        // },

        process.env.VUE_APP_OPEN_LOGISTICS !== "true"
          ? {
              label: "交易管理",
              children: [
                {
                  label: "竞价销售",
                  children: [
                    {
                      label: "我发布的",
                      path: "/released",
                      index: 6,
                    },
                    {
                      label: "我参与的",
                      path: "/participation",
                      index: 7,
                    },
                  ],
                },
                {
                  label: "竞价采购",
                  children: [
                    {
                      label: "我发布的",
                      path: "/purchase",
                      index: 8,
                    },
                    {
                      label: "我参与的",
                      path: "/take",
                      index: 9,
                    },
                  ],
                },
                {
                  label: "挂牌采购",
                  children: [
                    {
                      label: "我发布的",
                      path: "/purchasehangtag",
                      index: 10,
                    },
                  ],
                },
                {
                  label: "挂牌销售",
                  children: [
                    {
                      label: "我发布的",
                      path: "/markethangtag",
                      index: 11,
                    },
                  ],
                },
                // {
                //   label: "物流竞价",
                //   children: [
                //     {
                //       label: "我发布的",
                //       path: "/mylogistics",
                //       index: 30,
                //     },
                //     {
                //       label: "我参与的",
                //       path: "/participateIn",
                //       index: 31,
                //     },
                //   ],
                // },
                {
                  label: "订单信息",
                  path: "/order",
                  index: 12,
                },
                // {
                //   label: "物流竞价订单",
                //   path: "/LogisticsOrders",
                //   index: 33,
                // },
                // {
                //   label: "拼团信息",
                //   children: [
                //     {
                //       label: "我发布的",
                //       path: "/groupPublishing",
                //       index: 40,
                //     },
                //     {
                //       label: "我参与的",
                //       path: "/groupParticipation",
                //       index: 41,
                //     },
                //   ],
                // },
              ],
            }
          : {
              label: "交易管理",
              children: [
                {
                  label: "竞价销售",
                  children: [
                    {
                      label: "我发布的",
                      path: "/released",
                      index: 6,
                    },
                    {
                      label: "我参与的",
                      path: "/participation",
                      index: 7,
                    },
                  ],
                },
                {
                  label: "竞价采购",
                  children: [
                    {
                      label: "我发布的",
                      path: "/purchase",
                      index: 8,
                    },
                    {
                      label: "我参与的",
                      path: "/take",
                      index: 9,
                    },
                  ],
                },
                {
                  label: "挂牌采购",
                  children: [
                    {
                      label: "我发布的",
                      path: "/purchasehangtag",
                      index: 10,
                    },
                  ],
                },
                {
                  label: "挂牌销售",
                  children: [
                    {
                      label: "我发布的",
                      path: "/markethangtag",
                      index: 11,
                    },
                  ],
                },
                {
                  label: "物流竞价",
                  children: [
                    {
                      label: "我发布的",
                      path: "/mylogistics",
                      index: 30,
                    },
                    {
                      label: "我参与的",
                      path: "/participateIn",
                      index: 31,
                    },
                  ],
                },
                {
                  label: "订单信息",
                  path: "/order",
                  index: 12,
                },
                {
                  label: "物流竞价订单",
                  path: "/LogisticsOrders",
                  index: 33,
                },
                // {
                //   label: "拼团信息",
                //   children: [
                //     {
                //       label: "我发布的",
                //       path: "/groupPublishing",
                //       index: 40,
                //     },
                //     {
                //       label: "我参与的",
                //       path: "/groupParticipation",
                //       index: 41,
                //     },
                //   ],
                // },
              ],
            },
        {
          label: "人员管理",
          children: [
            {
              label: "人员架构",
              path: "/personnelStructure",
              index: 13,
            },
            {
              label: "操作记录",
              path: "/record",
              index: 14,
            },
            {
              label: "客户关系管理",
              path: "/customer",
              index: 45,
            },
          ],
        },
        {
          label: "签章管理",
          children: [
            {
              label: "我的签章",
              path: "/qianzhang",
              index: 15,
            },
          ],
        },
        {
          label: "我的收藏",
          children: [
            {
              label: "收藏的商品",
              path: "/guanzhu",
              index: 16,
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      active: 1,
    };
  },
  mounted() {
    let userId = Vue.ls.get(types.userId);
    let config = {
      dictType: "show_nysj",
      pageNum: 1,
      pageSize: 1,
    };
    this.data = [
      ...this.data,
      ...(this.ifOpenFwdt
        ? [
            {
              label: "服务大厅管理",
              children: [
                {
                  label: "我发布的服务",
                  path: "/myPublished",
                  index: 18,
                },
                {
                  label: "财税服务订单",
                  path: "/financeTax",
                  index: 19,
                },
                {
                  label: "金融服务订单",
                  path: "/banking",
                  index: 20,
                },
                {
                  label: "物流服务订单",
                  path: "/logistics",
                  index: 21,
                },
                {
                  label: "仓储服务订单",
                  path: "/warehouse",
                  index: 22,
                },
                {
                  label: "科技服务订单",
                  path: "/science",
                  index: 23,
                },
              ],
            },
            {
              label: "我的服务",
              children: [
                {
                  label: "我关注的服务商品",
                  path: "/usmyPublished",
                  index: 24,
                },
                {
                  label: "财税服务订单",
                  path: "/usfinanceTax",
                  index: 25,
                },
                {
                  label: "金融服务订单",
                  path: "/usbanking",
                  index: 26,
                },

                {
                  label: "物流服务订单",
                  path: "/uslogistics",
                  index: 27,
                },
                {
                  label: "仓储服务订单",
                  path: "/uswarehouse",
                  index: 28,
                },
                {
                  label: "科技服务订单",
                  path: "/usscience",
                  index: 29,
                },
              ],
            },
            {
              label: "合同相关",
              children: [
                {
                  label: "合同管理",
                  path: "/contract",
                  index: 16,
                },
                {
                  label: "中长期合同管理",
                  path: "/certification",
                  index: 17,
                },
              ],
            },
          ]
        : [
            {
              label: "合同相关",
              children: [
                {
                  label: "合同管理",
                  path: "/contract",
                  index: 16,
                },
                {
                  label: "中长期合同管理",
                  path: "/certification",
                  index: 17,
                },
              ],
            },
          ]),
    ];
    getSysDictDataByByDictType(config).then((res) => {
      let list = res.data.list;
      let showMoreMenuUserId = [];
      if (list && list.length > 0 && list[0].dictValue != "") {
        showMoreMenuUserId = list[0].dictValue.split(",");
        if (showMoreMenuUserId.includes(userId)) {
          console.log("includs");
          this.data = [
            {
              label: "资产管理",
              children: [
                {
                  label: "我的银行卡",
                  path: "/manage",
                  index: 1,
                },
                {
                  label: "我的资产",
                  path: "/myAssets",
                  index: 2,
                },
                {
                  label: "交易记录",
                  path: "/jiaoyi",
                  index: 3,
                },
                {
                  label: "账户安全",
                  path: "/finance",
                  index: 4,
                },
              ],
            },
            {
              label: "能源数据管理",
              children: [
                {
                  label: "基础信息",
                  path: "/BsInformation",
                  index: 30,
                },
                {
                  label: "数据报送",
                  path: "/Mining",
                  index: 5,
                },
              ],
            },
            {
              label: "交易管理",
              children: [
                {
                  label: "竞价销售",
                  children: [
                    {
                      label: "我发布的",
                      path: "/released",
                      index: 6,
                    },
                    {
                      label: "我参与的",
                      path: "/participation",
                      index: 7,
                    },
                  ],
                },
                {
                  label: "竞价采购",
                  children: [
                    {
                      label: "我发布的",
                      path: "/purchase",
                      index: 8,
                    },
                    {
                      label: "我参与的",
                      path: "/take",
                      index: 9,
                    },
                  ],
                },
                {
                  label: "挂牌采购",
                  children: [
                    {
                      label: "我发布的",
                      path: "/purchasehangtag",
                      index: 10,
                    },
                  ],
                },
                {
                  label: "挂牌销售",
                  children: [
                    {
                      label: "我发布的",
                      path: "/markethangtag",
                      index: 11,
                    },
                  ],
                },
                // {
                //   label: "物流竞价",
                //   children: [
                //     {
                //       label: "我发布的",
                //       path: "/mylogistics",
                //       index: 30,
                //     },
                //   ],
                // },
                {
                  label: "订单信息",
                  path: "/order",
                  index: 12,
                },
                {
                  label: "拼团信息",
                  path: "/pieceInfo",
                  index: 39,
                  children: [
                    {
                      label: "我发布的",
                      path: "/groupPublishing",
                      index: 40,
                    },
                    {
                      label: "我参与的",
                      path: "/groupParticipation",
                      index: 41,
                    },
                  ],
                },
                {
                  label: "合同管理",
                  path: "/contract",
                  index: 16,
                },
              ],
            },
            {
              label: "人员管理",
              children: [
                {
                  label: "人员架构",
                  path: "/personnelStructure",
                  index: 13,
                },
                {
                  label: "操作记录",
                  path: "/record",
                  index: 14,
                },
                {
                  label: "客户关系管理",
                  path: "/customer",
                  index: 45,
                },
              ],
            },
            {
              label: "签章管理",
              children: [
                {
                  label: "我的签章",
                  path: "/qianzhang",
                  index: 15,
                },
              ],
            },
            {
              label: "我的收藏",
              children: [
                {
                  label: "收藏的商品",
                  path: "/guanzhu",
                  index: 16,
                },
              ],
            },
            {
              label: "服务大厅管理",
              children: [
                {
                  label: "我发布的服务",
                  path: "/myPublished",
                  index: 18,
                },
                {
                  label: "财税服务订单",
                  path: "/financeTax",
                  index: 19,
                },
                {
                  label: "金融服务订单",
                  path: "/banking",
                  index: 20,
                },
                {
                  label: "物流服务订单",
                  path: "/logistics",
                  index: 21,
                },
                {
                  label: "仓储服务订单",
                  path: "/warehouse",
                  index: 22,
                },
                {
                  label: "科技服务订单",
                  path: "/science",
                  index: 23,
                },
              ],
            },
            {
              label: "我的服务",
              children: [
                {
                  label: "我关注的服务商品",
                  path: "/usmyPublished",
                  index: 24,
                },
                {
                  label: "财税服务订单",
                  path: "/usfinanceTax",
                  index: 25,
                },
                {
                  label: "金融服务订单",
                  path: "/usbanking",
                  index: 26,
                },

                {
                  label: "物流服务订单",
                  path: "/uslogistics",
                  index: 27,
                },
                {
                  label: "仓储服务订单",
                  path: "/uswarehouse",
                  index: 28,
                },
                {
                  label: "科技服务订单",
                  path: "/usscience",
                  index: 29,
                },
              ],
            },
          ];
        }
      }
    });
  },
  active() {
    this.active = Vue.ls.get("active");
  },
  methods: {
    downClick(row) {
      this.row = !this.row;
    },
    handleNodeClick(data) {
      if (data.path == "no") {
        this.$message({
          message: "系统正在建设中......",
          type: "warning",
        });
        return false;
      }
      this.$router.push({
        path: data.path,
      });
      console.log(data);
      this.linClick(data.index);
    },
    linClick(index) {
      this.active = index;
      Vue.ls.set("active", index);
      // if (index == 17) {
      //   Vue.ls.set("actives", 0);
      // }
    },
    getSysDictDataByByDictType() {
      console.log("........................2");
    },
  },
};
</script>

<style lang="scss" scoped>
.iconImg {
  margin-left: 5px;
}

.left {
  width: 186px;
  margin: 20px;
  /* border: 1px solid #F2F2F2; */
  border-top: 0;
  /* background: #fff; */
  text-align: center;
  /* color: #666666; */
  height: 663px;
  overflow: hidden;
  overflow-y: auto;

  .outer-box {
    /* background-color: #ffffff; */
    padding-top: 12px;
    padding-bottom: 10px;
  }

  .outer-box1 {
    /* background-color: #ffffff; */
    padding-top: 0px;

    .two {
      width: 100%;
      font-weight: 600;
    }
  }

  .one {
    font-size: 18px;
    height: 47px;
    font-weight: 550;
    line-height: 47px;
    /* background: #f3f3f3; */
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
  }

  .one span {
    display: inline-block;
    width: 110px;
  }

  .active {
    font-weight: bolder !important;
  }

  .two {
    font-size: 16px;
    display: block;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    width: 136px;
  }

  .outer-box1 {
    padding-top: 0;

    .two {
      font-size: 16px;
      font-weight: 550px;
      font-weight: 550;
    }
  }

  .two-active {
    height: 40px;
    /* background: linear-gradient(-45deg, #00a0ff, #0a65ff); */
    background-color: none !important;
    /* box-shadow: 0px 5px 20px 0px rgba(143, 170, 249, 0.47);
      border-radius: 20px;
      line-height: 40px;
      color: #ffffff !important; */
  }

  .three {
    font-size: 14px;
    display: block;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    width: 136px;
    color: #666666;
  }
}

::v-deep {
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .el-tree {
    background: none !important;
  }

  .el-tree-node__content {
    height: 47px;
    /* background: #f9f9f9; */
    background: none !important;
    /* border-top: 1px solid #ccc; */
    font-weight: bold;

    .el-tree-node__label {
      font-size: 18px;
      /* margin: 0 auto; */
    }
  }

  .el-tree-node__children {
    .el-tree-node__content {
      background: none !important;
      border: none;
      font-weight: normal;

      .el-tree-node__label {
        font-size: 16px;
      }
    }
  }

  .el-tree-node__label {
    /* font-weight: bolder !important; */
  }

  .el-tree-node.is-current > .el-tree-node__label {
    /* font-weight: bolder !important; */
    /* background-color: rgba(239, 243, 254, 1) !important; */
  }

  .el-tree-node__content {
    padding-left: 10px !important;
  }
}

::v-deep .is-current > .el-tree-node__content > .el-tree-node__label {
  font-weight: bolder !important;
}
</style>
